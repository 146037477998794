// import React, { Component } from 'react'
import { graphql } from 'gatsby'

import Page from './page'

export default Page

// If new ACF fields are added you'll need to restart dev tools for graphql to pick them up
export const pageQuery = graphql`
    fragment WPProject on wordpress__wp_project {
        wordpress_id
        id
        title
        slug
        type
        date
    }

    query($id: String!) {
        wordpressWpProject(id: { eq: $id }) {
            ...WPProject
            blocks {
                ...ProjectBlocks
                attrs {
                    ...ProjectBlockAttrs
                    image {
                        ...ProjectBlockBackgroundImages,
                        localFile {
                            childImageSharp {
                                fluid ( quality: 90, srcSetBreakpoints: [ 640, 768, 1024, 1366, 1600, 1920 ] ) {
                                    ...BlockImageProperties
                                }
                            }
                        }
                    }
                }
            }
        }
        site {
            id
            siteMetadata {
                title
            }
        }
    }
`
